import { useEffect } from "react";
import APICall from "../../APIConnect";
import TagManager from "react-gtm-module";

const GTManager = () => {
    useEffect(() => {
        let included = false;
        let tags = document.head.querySelectorAll("script");
        tags.forEach(tag => {
            if (tag.src.includes("tagmanager")) {
                included = true;
            }
        });
        if (!included) {
            APICall("GET", "/configuracoes").then(response => {
                if (response.data.status !== 200) {
                    window.alert("Ocorreu um erro ao solicitar os dados do site.");
                } else {
                    if (response.data.success.configuracoes.tag_manager) {
                        if (response.data.success.configuracoes.tag_manager.length > 5) {
                            const tagManagerArgs = {
                                gtmId: response.data.success.configuracoes.tag_manager
                            }
                            let checkCookies = setInterval(() => {
                                let accept = localStorage.getItem("acceptCookiesDesnecessarios");
                                if (accept) {

                                    TagManager.initialize(tagManagerArgs)
                                    clearInterval(checkCookies);
                                }
                            }, 3000);
                        }
                    }
                }
            });
        }
    }, []);

    return (<></>);

}

export default GTManager;