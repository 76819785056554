import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { Suspense, lazy, useEffect, useState } from 'react';

import "./index.css";
import ScrollToTop from "./Helpers/ScrollToTop";
import Metas from "./Helpers/Metas";
import SEOHelper from "./Helpers/SEOHelper";
import APICall from "./APIConnect";
import Cookies from "./Components/Cookies";
import GTManager from "./Components/GTManager";

const Header = lazy(() => import('./Parts/Header'));
const Footer = lazy(() => import('./Parts/Footer'));

const Home = lazy(() => import('./Pages/Home'));
const Sobre = lazy(() => import('./Pages/Sobre'));
const Blog = lazy(() => import('./Pages/Blog'));
const Post = lazy(() => import('./Pages/Post'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Obrigado = lazy(() => import('./Pages/Obrigado'));
const FaleConosco = lazy(() => import('./Pages/FaleConosco'));

const router = createBrowserRouter([
  {
    path: "*",
    element: <Main />,
  },
]);

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Suspense fallback={<div className="suspense"></div>}>
          <RouterProvider router={router} />
        </Suspense>
      </div>
    </HelmetProvider>
  );
}

function Main() {
  const [withCache, setCache] = useState<boolean>(false);
  useEffect(() => {
    APICall("GET", "/configuracoes").then(response => {
      if (response.data.status !== 200) {
        window.alert("Ocorreu um erro ao solicitar os dados do site.");
      } else {
        let configuracoes: any | undefined = response.data.success?.configuracoes;
        if (configuracoes && configuracoes.withCache === "true") {
          setCache(true);
        } else {
          setCache(false);
        }
      }
    });
  });
  return (
    <>
      <Metas />
      <ScrollToTop />
      <SEOHelper key={`seo_helper_updater_${withCache ? "cache" : "no_cache"}`} withCache={withCache} />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<Post />} />
        <Route path="/produtos" element={<Produtos />} />
        <Route path="/produto/:slug" element={<Produto />} />
        <Route path="/obrigado/:slug" element={<Obrigado />} />
        <Route path="/fale-conosco" element={<FaleConosco />} />
        <Route path="/trabalhe-conosco" element={<FaleConosco curriculo={true} />} />
      </Routes>
      <Footer />
      <Cookies />
      <GTManager />
    </>
  )
}

export default App;
