import { useEffect, useState } from "react";
import styles from "./Cookies.module.css";

const Cookies = () => {
    const [modal, setModal] = useState(false);
    const [desnecessarios, setDesnecessarios] = useState(true);
    const [showCookies, setShowCookies] = useState(false);
    useEffect(() => {
        if (!localStorage.acceptCookies) {
            setShowCookies(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem("acceptCookies", new Date().toString());
        if (desnecessarios) {
            localStorage.setItem("acceptCookiesDesnecessarios", new Date().toString());
        }
        setShowCookies(false);
    }

    return (
        <>
            <div className={`${styles.adjust_cookies_modal} ${!modal ? styles.hide : ""}`}>
                <div className={styles.adjust_cookies_modal_content}>
                    <div className={styles.adjust_coookies_modal_header}>
                        <div className={styles.adjust_coookies_modal_topo}>
                            <p className={styles.title}>Visão geral de privacidade</p>
                            <button className={`${styles.btn_fechar_modal} ${styles.toggle_modal}`} onClick={() => setModal(false)}>
                                X
                            </button>
                        </div>
                        <p>
                            Este site usa cookies para melhorar sua experiência enquanto você navega pelo site. Destes cookies,
                            os cookies que são categorizados como necessários são armazenados no seu navegador, pois são
                            essenciais para o funcionamento das funcionalidades básicas do site. Também usamos cookies de
                            terceiros que nos ajudam a analisar e entender como você usa este site. Esses cookies serão
                            armazenados em seu navegador apenas com o seu consentimento. Você também tem a opção de cancelar
                            esses cookies. Porém, a desativação de alguns desses cookies pode afetar sua experiência de
                            navegação.
                        </p>
                    </div>
                    <div className={styles.adjust_cookies_modal_body}>
                        <div className={styles.cookie}>
                            <p className={styles.subtitle}>Cookies Necessários</p>
                            <div className={styles.cookie_active}>Sempre ativado</div>
                            <p>Os cookies necessários são absolutamente essenciais para o funcionamento adequado do site. Esta
                                categoria inclui apenas cookies que garantem funcionalidades básicas e recursos de segurança do
                                site. Esses cookies não armazenam nenhuma informação pessoal.</p>
                        </div>
                        <div className={styles.cookie}>
                            <p className={styles.subtitle}>Cookies Não Necessários</p>
                            <div className={styles.cookie_active}>
                                <span className={styles.active_desnecessario}>{desnecessarios ? "Ativado" : "Desativado"}</span>
                                <label className={styles.switch}>
                                    <input type="checkbox" checked={desnecessarios} id="cookies_desnecessarios" onChange={(e) => setDesnecessarios(e.target.checked)} />
                                    <label htmlFor="cookies_desnecessarios"><span className={`${styles.slider} ${styles.round}`}></span></label>
                                </label>
                            </div>
                            <p>Quaisquer cookies que possam não ser particularmente necessários para o funcionamento do site e
                                sejam usados ​​especificamente para coletar dados pessoais do usuário por meio de análises,
                                anúncios e outros conteúdos incorporados.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.accept_cookies} ${!showCookies ? styles.hide : ""}`}>
                <div className={styles.accept_cookies_header}>
                    <p className={styles.accept_cookies_message}>
                        Usamos cookies em nosso site para fornecer uma experiência mais relevante, lembrando suas preferências e
                        visitas repetidas. Ao clicar em “Aceitar”, concorda com a utilização de todos os cookies
                    </p>
                </div>
                <div className={styles.accept_cookies_buttons}>
                    <button className={`${styles.adjust} ${styles.toggle_modal}`} id="adjustCookiesButton" onClick={() => setModal(true)}>
                        Ajustes
                    </button>
                    <button className={styles.accept} id="acceptCookiesButton" onClick={() => acceptCookies()}>
                        Aceitar
                    </button>
                </div>
            </div>
        </>
    )
}

export default Cookies;