import axios from "axios";
import APICall from "../../APIConnect";
import { InfoT, MetasT } from "./interfaces";

const setDefaultMetas = () => {
    APICall("GET", "/v2/seo/home").then(response => {
        if (response.data.status !== 200) {
            // window.alert("Ocorreu um erro ao carregar os dados do site, por favor, tenta novamente.");
        } else {
            window.defaultMetas = response.data.success;
        }
    });
}

const callForMetaUpdate = (metas: MetasT) => {
    let mts = { ...window.defaultMetas, ...metas };

    if (mts.title) {
        document.title = mts.title;
    }

    let infos: Array<InfoT> = [
        { name: "description", value: mts.description ? mts.description : "" },
        { name: "image", value: mts.imagem && mts.imagem.generic ? `${mts.imagem.generic}.jpg?size=1200` : "" },
        { name: "keywords", value: mts.keywords ? mts.keywords : "" },
        { name: "og:title", value: mts.title ? mts.title : "" },
        { name: "og:description", value: mts.description ? mts.description : "" },
        { name: "og:image", value: mts.imagem && mts.imagem.generic ? `${mts.imagem.generic}.jpg?size=1200` : "" },
        { name: "og:image:width", value: mts.width ? mts.width : "" },
        { name: "og:image:height", value: mts.height ? mts.height : "" },
        { name: "og:url", value: window.location.href },
        { name: "og:site_name", value: mts.app_title ? mts.app_title : "" },
        { name: "og:type", value: mts.type ? mts.type : "" },
        { name: "twitter:card", value: mts.card ? mts.card : "" },
        { name: "twitter:site", value: mts.twitter ? mts.twitter : "" },
        { name: "twitter:title", value: mts.title ? mts.title : "" },
        { name: "twitter:description", value: mts.description ? mts.description : "" },
        { name: "twitter:image", value: mts.imagem && mts.imagem.generic ? `${mts.imagem.generic}.jpg?size=1200` : "" },
    ];

    infos.forEach(info => updateMeta(info));
}

const updateMeta = (i: InfoT) => {
    let meta: Element | null = null;
    meta = document.querySelector(`meta[name="${i.name}"]`);
    if (!meta) {
        meta = document.createElement("meta");
        meta.setAttribute("name", i.name);
        meta.setAttribute("property", i.name);
        document.head.appendChild(meta);
    }
    meta.setAttribute("content", i.value);
}

function callCacheGenerate() {
    if (isAllowed()) {
        let data = new FormData();
        data.append("head", getStaticHead());
        data.append("body", getRepleaceableBody());
        data.append("url", window.location.pathname);
        axios.post(`https://${window.location.hostname}/SEOHelper/SEOHelper.php`, data);
    }
}

function isAllowed() {
    let response = true;
    if (window.location.hostname.includes("local")) {
        return false;
    }
    const disableCache = [
        "carrinho",
        "minha-conta",
        "pedido",
        "obrigado",
        "detalhes",
        "clientes"
    ];

    disableCache.forEach(route => {
        if (window.location.pathname.includes(route)) {
            response = false;
        }
    });

    return response;
}

function getStaticHead() {
    let infos = [
        "description",
        "image",
        "keywords",
        "og:title",
        "og:description",
        "og:image",
        "og:image:width",
        "og:image:height",
        "og:url",
        "og:site_name",
        "og:type",
        "twitter:card",
        "twitter:site",
        "twitter:title",
        "twitter:description",
        "twitter:image"
    ];

    let newHTML = "";
    infos.forEach(info => { newHTML = newHTML + getMeta(info) });
    let title = document.querySelector("title")?.outerHTML;
    if (title) {
        newHTML = newHTML + title;
    }
    return newHTML;
}

function getMeta(i: string) {
    let meta: Element | null = null;
    meta = document.querySelector(`meta[name="${i}"]`);

    let tag: string;
    if (meta) {
        tag = meta.outerHTML;
    } else {
        meta = document.querySelector(`meta[property="${i}"]`);
        if (meta) {
            tag = meta.outerHTML;
        } else {
            tag = `<meta name="${i}" property="${i}" content="" />`;
        }
    }

    return tag;
}

function getRepleaceableBody() {
    let root = document.querySelector("#root");
    if (root) {
        return root.outerHTML.replace('<div class="App">', '<div class="App" style="opacity: 0;">');
    } else {
        return "";
    }
}

export {
    callForMetaUpdate,
    setDefaultMetas,
    callCacheGenerate
}